import React from 'react'
import pic02 from '../assets/images/pic02.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const One = props => (
  <section
    id="one"
    className="spotlight style1 bottom inactive"
    style={{ backgroundImage: `url(${pic02})` }}
  >
    <span className="image fit main">
      <img src={pic02} alt="" />
    </span>
    <Fade bottom big>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-4 col-12-medium">
              <header>
                <h2>Tradition and Innovation</h2>
                <p>
                  
                </p>
              </header>
            </div>
            <div className="col-4 col-12-medium">
              <p>
              Șaraiman Arts is an arts management agency composed of an international group of performers and activists whose mission is to place underrepresented artists in the spotlight they deserve and connect them with appreciative audiences in premier venues.  
              </p>
            </div>
            <div className="col-4 col-12-medium">
              <p>
              In doing so, we hope to build bridges between tradition and innovation, empowering artists to explore new creative avenues while preserving traditional cultural practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
    <ScrollLink
      to="two"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)
export default One
