import React from 'react'
import pic03 from '../assets/images/pic03.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Two = props => (
  <section
    id="two"
    className="spotlight style2 right inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h2>Our story</h2>
          <p></p>
        </header>
        <p>
        Șaraiman Arts was created in 2017 by two musicians from Iran and the United States who came to Romania to pursue their passion for Romani music. Through the process of learning and collaborating with Romani wedding musicians (lăutari), they found that many great performers are struggling to adapt to the realities of the 21st century musical economy and do not have the resources to promote their work outside the wedding circuit. We act as an advocate and intermediary for performers engaged in a range of musical genres, and our artists retain full creative control over their work.
        </p>
        {/* <ul className="actions">
          <li>
            <a href="/" className="button">
              Learn More
            </a>
          </li>
        </ul> */}
      </div>
    </Fade>
    <ScrollLink
      to="three"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Two
