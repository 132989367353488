import React from 'react'
import pic04 from '../assets/images/pic04.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Three = props => (
  <section
    id="three"
    className="spotlight style3 left inactive"
    style={{ backgroundImage: `url(${pic04})` }}
  >
    <span className="image fit main bottom">
      <img src={pic04} alt="" />
    </span>
    <Fade left big>
      <div className="content">
        <header>
          <h2>Our artists</h2>
          <p>Șaraiman Arts represents an ecelectic array of artists working in traditional genres, including muzică lăutărească, manele, Transylvanian fiddle music, and Romani folk-pop.</p>
        </header>
        <h4><ul>
          <li>Corina Sîrghi și Taraful Jean Americanu</li>
          <li>Elvis Rromano and his Rock 'N' Rrom Band</li>
          <li>Volekh Quartet (klezmer)</li>
          <li>Țagoi și Taraful de la Clejani</li>
          <li>Vălenii de Mureș (Transylvanin string band)</li>
        </ul>
        </h4>
{/*         
        <p>
        </p>
        <ul className="actions">
          <li>
            <a href="/" className="button">
              Learn More
            </a>
          </li>
        </ul> */}
      </div>
    </Fade>
    <ScrollLink
      to="four"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Three
